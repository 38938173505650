<template>
  <div class="nav-promo-product-card" v-if="getProduct">
    <LinkTag @click.native="handleClick" interstitial :to="getProductLink">
      <div class="nav-promo-product-card__image-holder mb-16">
        <bleach-image
          v-if="getBackground"
          class="nav-promo-product-card__background-image"
          :src="getBackground.original_secure_url"
          :sizes="{
            md: [280, 'auto', 'zoom_1.2'],
            lg: [300, 'auto', 'zoom_1.2']
          }"
          :default-size="[250, 'auto', 'zoom_1.2']"
          :alt="
            getBackground.metadata.alt ||
              getProduct.displayTitle + ' background'
          "
          imageQuality="60"
        />
        <bleach-image
          v-if="getImage"
          class="nav-promo-product-card__product-image"
          :src="getImage.original_secure_url"
          :sizes="{
            md: [280, 'auto', 'zoom_1.2'],
            lg: [300, 'auto', 'zoom_1.2']
          }"
          :default-size="[250, 'auto', 'zoom_1.2']"
          :alt="getImage.metadata.alt || getProduct.displayTitle + ' image'"
          imageQuality="60"
        />
      </div>
      <div class="nav-promo-product-card__text">
        <div class="text-commerce-heading-3 text--clr-black mb-8">
          {{ getProduct.displayTitle }}
        </div>
        <ReviewStars
          :rating="getAverageRating"
          class="nav-promo-product-card__stars mb-24"
          :uniqueId="`nav-promo-product-card` + getProduct.sku"
        />
        <div
          class="text-commerce-button-primary text--clr-black text--uppercase"
        >
          {{ UI_ELEMENT("nav_menu_ecom_cta") }}
        </div>
      </div>
    </LinkTag>
  </div>
</template>

<script>
import ReviewStars from "@/components/reviews/ReviewStars"

import ProductPageReviewsMixin from "@/views/ProductPage/ProductPageReviews/ProductPageReviewsMixin"

import GTMAPI from "@/integration/GTMAPI"

import { mapGetters } from "vuex"

export default {
  mixins: [ProductPageReviewsMixin],

  props: {
    reference: Object
  },

  components: {
    ReviewStars
  },

  methods: {
    handleClick() {
      GTMAPI.trackEvent("navigation.promo.clicked", {
        item_title: this.getProduct.title
      })
    }
  },

  computed: {
    ...mapGetters("content", ["GET_PRODUCT_ENTRY"]),

    getProduct() {
      return (
        this.reference &&
        this.GET_PRODUCT_ENTRY(this.reference.sys.id, this.reference.__typename)
      )
    },

    getBackground() {
      return this.getProduct.heroBackground && this.getProduct.heroBackground[0]
    },

    getImage() {
      return (
        (this.getProduct.packshot && this.getProduct.packshot[0]) ||
        (this.getProduct.images && this.getProduct.images[0])
      )
    },

    getProductLink() {
      return {
        name: this.getProduct.__typename,
        params: { slug: this.getProduct.slug }
      }
    }
  }
}
</script>

<style lang="scss">
.nav-promo-product-card {
  position: relative;
  width: 100%;
  height: auto;

  &__image-holder {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    overflow: hidden;
  }

  &__background-image,
  &__product-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
</style>
