var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getProduct)?_c('div',{staticClass:"nav-promo-product-card"},[_c('LinkTag',{attrs:{"interstitial":"","to":_vm.getProductLink},nativeOn:{"click":function($event){return _vm.handleClick.apply(null, arguments)}}},[_c('div',{staticClass:"nav-promo-product-card__image-holder mb-16"},[(_vm.getBackground)?_c('bleach-image',{staticClass:"nav-promo-product-card__background-image",attrs:{"src":_vm.getBackground.original_secure_url,"sizes":{
          md: [280, 'auto', 'zoom_1.2'],
          lg: [300, 'auto', 'zoom_1.2']
        },"default-size":[250, 'auto', 'zoom_1.2'],"alt":_vm.getBackground.metadata.alt ||
            _vm.getProduct.displayTitle + ' background',"imageQuality":"60"}}):_vm._e(),(_vm.getImage)?_c('bleach-image',{staticClass:"nav-promo-product-card__product-image",attrs:{"src":_vm.getImage.original_secure_url,"sizes":{
          md: [280, 'auto', 'zoom_1.2'],
          lg: [300, 'auto', 'zoom_1.2']
        },"default-size":[250, 'auto', 'zoom_1.2'],"alt":_vm.getImage.metadata.alt || _vm.getProduct.displayTitle + ' image',"imageQuality":"60"}}):_vm._e()],1),_c('div',{staticClass:"nav-promo-product-card__text"},[_c('div',{staticClass:"text-commerce-heading-3 text--clr-black mb-8"},[_vm._v(" "+_vm._s(_vm.getProduct.displayTitle)+" ")]),_c('ReviewStars',{staticClass:"nav-promo-product-card__stars mb-24",attrs:{"rating":_vm.getAverageRating,"uniqueId":`nav-promo-product-card` + _vm.getProduct.sku}}),_c('div',{staticClass:"text-commerce-button-primary text--clr-black text--uppercase"},[_vm._v(" "+_vm._s(_vm.UI_ELEMENT("nav_menu_ecom_cta"))+" ")])],1)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }