<template>
  <div v-if="reference" class="nav-menu-promo-section">
    <div
      v-if="appearance !== 'default' || GET_IS_ABOVE_BREAKPOINT('lg')"
      class="nav-menu-promo-section__title text-commerce-heading-2 text--clr-black mb-20"
    >
      {{ title }}
    </div>
    <component
      :is="getPromoComponent"
      :content="getContent"
      :reference="reference"
      :appearance="appearance"
      :title="title"
    />
  </div>
</template>

<script>
import NavigationMenuPromoGroup from "./Group/NavigationMenuPromoGroup.vue"
import NavigationMenuPromoProduct from "./Product/NavigationMenuPromoProduct.vue"
import NavigationMenuPromoMediaItem from "./MediaItem/NavigationMenuPromoMediaItem.vue"

import { mapGetters } from "vuex"

export default {
  props: {
    title: String,
    reference: Object,
    appearance: String
  },

  components: {
    NavigationMenuPromoGroup,
    NavigationMenuPromoProduct,
    NavigationMenuPromoMediaItem
  },

  computed: {
    ...mapGetters("breakpoints", ["GET_IS_ABOVE_BREAKPOINT"]),

    getContent() {
      return ["MediaItem", "Group"].includes(this.reference.__typename)
        ? this.reference
        : null
    },

    getPromoComponent() {
      let components = {
        Group: NavigationMenuPromoGroup,
        MediaItem: NavigationMenuPromoMediaItem,
        Product: NavigationMenuPromoProduct,
        ProductBundle: NavigationMenuPromoProduct
      }

      return this.reference && components[this.reference.__typename]
    }
  }
}
</script>

<style lang="scss"></style>
